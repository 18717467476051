<!-- 自定义报表 -->
<template>
  <div class="companySales">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 所有报表 -->
      <el-tab-pane label="所有报表" name="AllReport">
        <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
        <report-table ref="AllReport" @changeDialogTit="changeDialogTit"></report-table>
      </el-tab-pane>
      <!-- 我的报表 -->
      <el-tab-pane label="我的报表" name="MyReport">
        <breadcrumb :BreadcrumbData="BreadcrumbData2"></breadcrumb>
        <el-button @click="() => {newAddReport()}" class="common-screen-btn common-noMarginLeft" type="primary" >新增报表</el-button>
        <!-- 列表 -->
        <report-table ref="MyReport" :typeName="'MyReport'" @changeDialogTit="changeDialogTit"></report-table>
      </el-tab-pane>
    </el-tabs>

    <!-- 弹弹弹 -->
    <el-dialog
      :title="dialogTit"
      append-to-body
      width="580px"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="报表名称：" prop="recordName">
          <el-input v-model="form.recordName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="报表类型：" prop="recordType">
          <el-radio-group @change="radioChange" v-model="form.type" :disabled="dialogTit == '编辑报表'">
            <el-radio label="1">
              公司维度
              <el-tooltip effect="dark" content="【说明】公司报表可以创建销售、财务、质检等公司数据统计报表" placement="bottom" enterable>
                  <img src="../../assets/images/question.png" class="tit-img"/>
              </el-tooltip>
            </el-radio>
            <el-radio label="2">
              人员维度
              <el-tooltip effect="dark" content="【说明】人员报表可以创建销售、客服、质检人员数据统计报表" placement="bottom" enterable>
                  <img src="../../assets/images/question.png" class="tit-img"/>
              </el-tooltip>
            </el-radio>
            <el-radio label="3">
              数据维度
              <el-tooltip effect="dark" content="【说明】数据报表可以创建客户、店铺、客服数据统计报表" placement="bottom" enterable>
                  <img src="../../assets/images/question.png" class="tit-img"/>
              </el-tooltip>
              </el-radio>
          </el-radio-group>
          <el-radio-group style="margin-top: 16px" class="sales" v-show="form.type == '2'" v-model="form.recordType" :disabled="dialogTit == '编辑报表'">
            <el-radio label="2">销售人员报表</el-radio>
            <el-radio label="3">质检人员报表</el-radio>
            <el-radio label="4">考勤人员报表</el-radio>
          </el-radio-group>
          <el-radio-group style="margin-top: 16px" class="sales" v-show="form.type == '3'" v-model="form.recordType" :disabled="dialogTit == '编辑报表'">
            <el-radio label="5">客户数据报表</el-radio>
            <el-radio label="6">店铺数据报表</el-radio>
            <el-radio label="7">客服数据报表</el-radio>
            <el-radio style="margin-top: 16px" label="8">店铺服务数据</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="报表说明：">
          <el-input v-model="form.remark" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" class="common-screen-btn">取 消</el-button>
        <el-button type="primary" @click="() => {sendForm('form')}" class="common-screen-btn">设置报表</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import reportTable from "./children/reportTable.vue";
import { reportAddEdit } from "../../service/report.js"


export default {
  name: "customeReport",
  components: {
    Breadcrumb,
    reportTable
  },
  props: {},
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "自定义报表", isLink: false },
        { title: "所有报表", isLink: false }
      ],
      BreadcrumbData2: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "自定义报表", isLink: false },
        { title: "我的报表", isLink: false }
      ],
      
      tableData: [],
      activeName: "MyReport",
      tabName: "", //激活的tab
      dialogFormVisible: false,
      dialogTit: "",
      form: {
        type: '1',
        recordType: '1'
      },
      rules: {
					recordName: [{ required: true, message: "请填写报表名称", trigger: "change"}],
					recordType: [{ required: true, message: "请选择报表类型", trigger: "change"}],
				},
      radio: 1,
      radio2: null
    };
  },
  
  methods: {
    handleClick(tab) { // 切换tab
      this.$refs[tab.name].reportList()
    },
    resetBtn() {
      this.params = {
        page: 1,
        pageSize: 10
      };
      this.reportList()
    },
    newAddReport() {
      this.form = {
        type: '1',
        recordType: '1'
      }
      if (this.$refs["form"]) {this.$refs["form"].resetFields();}
      this.dialogTit = "新增报表";
      this.dialogFormVisible = true;
    },
    changeDialogTit(editInfo) {
      this.form = {
        id: editInfo.id,
        recordName: editInfo.recordName,
        type: '1',
        recordType: editInfo.recordType,
        remark: editInfo.remark
      }
      if (editInfo.recordType == '1') {
        this.form.type = '1'
      } else if(editInfo.recordType == '2' || editInfo.recordType == '3' || editInfo.recordType == '4') {
        this.form.type = '2'
      } else {
        this.form.type = '3'
      }
      this.dialogTit = '编辑报表';
      this.dialogFormVisible = true;
    },
    radioChange(val) {
      if (val == '1') {
        this.form.recordType = '1';
      } else if(val == '2') {
        this.form.recordType = '2'
      } else if(val == '3') {
        this.form.recordType = '5'
      }
    },
    handleClose() {
      this.form = {};
      this.dialogFormVisible = false;
    },
    sendForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) { // 校验成功
          let form = {...this.form}
          delete form.type
          if (form.recordType == '1') {
            form.elementUuid = '4e3cda8618814b91b8ee880954916f57'
          } else if(form.recordType == '2') {
            form.elementUuid = '52d8d3a9f4d3423d81642414b0f018b3'
          } else if(form.recordType == '3') {
            form.elementUuid = 'c6d23fdeea9d460789d161ca243c6f37'
          } else if(form.recordType == '4') {
            form.elementUuid = '4be90c8f6da0496b8b872453f7b4f9ee'
          } else if(form.recordType == '5') {
            form.elementUuid = 'ce4e7404112b469db2277363b8095278'
          } else if(form.recordType == '6') {
            form.elementUuid = '6553971f6dd34f2a8eb2cda7ed689348'
          } else if(form.recordType == '7') {
            form.elementUuid = '67128da700f84dcbb7beff8ab3e37eb7'
          } else if(form.recordType == '8') {
            form.elementUuid = '0c724e0e59f040f98bddd9720ec02545'
          }
          let { code, data } = await reportAddEdit(form)
          if ( code == 200 ) {
            // this.$message.success('添加成功')
            // this.$refs.MyReport.reportList()  // 刷新列表
            this.dialogFormVisible = false;
            this.$router.push(`/custom-report/report-setting/${data.id}?elementUuid=${data.elementUuid}`)
          }
        } else {
          return false;
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.companySales {
  text-align: left;
  position: relative;
  // /deep/ .el-tabs__header {
  //   width: 120%;
  //   margin-left: -16px;
  // }
  /deep/.el-tabs__content {
    padding: 16px 32px;
  }
  
}
.sales {
  border-left: 4px solid #1890ff;
  padding: 6px 0 4px 8px;
}
</style>
